<template>
  <div class="container relative">
    <div v-if="!isLoaded" class="h-full flex md:w-full gap-x-5 md:gap-x-[50px] pl-6 md:pl-0 overflow-hidden">
      <OfferCarouselItem
        v-for="(item, index) in items"
        :key="index"
        class="relative block w-full overflow-hidden h-full flex-shrink-0"
        :class="{
          'Offer-bullet ': items.length > 1 && index === 0,
          'pr-6': items.length === 1,
        }"
        :item="item"
        :lazy="lazy"
        :preload="preload && index === 0"
      />
    </div>

    <Swiper
      v-show="isLoaded"
      :modules="[SwiperAutoplay, SwiperNavigation]"
      :slider-per-view="0.5"
      :space-between="20"
      :slides-offset-before="0"
      :centered-slides="false"
      :center-insufficient-slides="false"
      :loop="true"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      :breakpoints="breakpoints"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      class="w-full h-full"
    >
      <SwiperSlide v-for="(item, index) in items" :key="index">
        <OfferCarouselItem :item="item" :lazy="lazy" class="w-full h-full" />
      </SwiperSlide>
      <SwiperControls
        :current-index="currentIndex"
        @change="($event: number) => (currentIndex = $event)"
        @is-loaded="isSwiperLoaded"
      />
    </Swiper>

    <ul
      v-show="isLoaded && !isMobile"
      class="mt-5 [ text-white ] [ hidden md:flex flex-row gap-x-5 items-center justify-center ]"
    >
      <li v-for="(i, idx) in items" :key="i?.id ?? idx" class="Bullet">
        <button
          :aria-label="`slide-to ${idx + 1}`"
          class="w-full h-full"
          :class="{
            'Bullet--active': currentIndex === idx,
            'Bullet-not-active': currentIndex !== idx,
          }"
          @click="currentIndex = idx"
        ></button>
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import type { BrandOfferCardFragment } from '~/graphql/Brand';
import type { OfferCardFragment } from '~/graphql/fragments';
const currentIndex = ref(0);
const { isMobile } = useDevice();

type Offer = OfferCardFragment | BrandOfferCardFragment;
const props = withDefaults(
  defineProps<{
    offers: Offer[];
    lazy: boolean;
    preload?: boolean;
  }>(),
  {
    offers: () => [],
    lazy: false,
    preload: false,
  },
);

const isLoaded = ref(false);

const isSwiperLoaded = () => {
  isLoaded.value = true;
};

const items = toRef(props, 'offers');

const breakpoints = {
  '320': {
    slidesPerView: items.value.length === 1 ? 1.1 : 1,
    spaceBetween: 10,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 10,
    center: true,
  },
  '768': {
    slidesPerView: 1,
    spaceBetween: 50,
    slidesOffsetBefore: 0,
  },
};
</script>
<style scoped lang="postcss">
.mask {
  filter: brightness(45%);
}

@screen md {
  .highlight {
    @apply relative;

    &::after {
      content: '';
      background: url('~/assets/icons/white-path.svg');
      background-size: 100% 100%;
      height: 50px;
      width: 70px;
      position: absolute;
      right: -30px;
      bottom: 0;
      background-repeat: no-repeat;
    }
  }
}

.Offer-bullet {
  width: 86%;

  @screen md {
    width: 100%;
  }
}

.Bullet {
  @apply flex items-center justify-center bg-primary-A900;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;

  &--active {
    @apply border-2  border-primary-A900 rounded-full p-2;
  }

  &--not-active {
    @apply border-2 border-white rounded-full p-2 bg-primary-A900;
  }
}

.container {
  --max-width: 2800px;
  --width: min(calc(100vw - var(--scroll-width, 0px)), var(--max-width));
  max-width: var(--max-width);
  width: var(--width);
  height: calc(var(--width) / 2);
}
</style>
